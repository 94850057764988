<template>
  <div class="login-bg1">
    <div class="register-logo clearfix">
      <div class="logo-pic">
        <img src="@/assets/image/login/lg1.png">
      </div>
    </div>
    <div class="center-opcity"></div>
    <div class="login">
      <div class="pos-tit">
        <div class="tabe-tit1">中科可控供需协同平台</div>
        <div class="tabe-tit2">一个平台、多方共赢</div>
      </div>
      <div class="login-box">
        <!-- 短信登录 -->
        <news-login @goPassword="goPassword" ref="getNewsLogin"></news-login>
        <!-- 密码登录 -->
        <password-login @goNews="goNews"  ref="getPasswordLogin"></password-login>
      </div>
    </div>
    <div class="login-footer">
      <div>版权所有 © 2001-2021 中科可控信息产业有限公司  All rights reserved.
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">苏ICP备18044399号-1</a>
      </div>
    </div>
  </div>
</template>
<script>
import NewsLogin from './loginComponents/login/NewsLogin.vue'
import PasswordLogin from './loginComponents/login/PasswordLogin.vue'
export default {
  components: { NewsLogin, PasswordLogin },
  name: 'Login',
  data () {
    return {}
  },
  created () {},
  mounted () {},
  methods: {
    goNews () {
      this.$refs.getPasswordLogin.showPassWordLogin = false
      this.$refs.getNewsLogin.showNewsLogin = true
      this.$refs.getPasswordLogin.PassResetForm('passWordRuleForm')
      this.$refs.getNewsLogin.NewsResetForm('newsRuleForm')
    },

    goPassword () {
      this.$refs.getPasswordLogin.showPassWordLogin = true
      this.$refs.getNewsLogin.showNewsLogin = false
      this.$refs.getPasswordLogin.PassResetForm('passWordRuleForm')
      this.$refs.getNewsLogin.NewsResetForm('newsRuleForm')
    }
  }
}
</script>

<style scoped lang='scss'>
  .logo-pic, .logo-tit{
    float: left;
  }
  .logo-pic{
    margin: 32px 15px 0 0;
  }
  .logo-tit{
    margin-top: 9px;
  }
  .logo-pic img{
    width: 122px;
  }
  .register-logo{
    height: 80px;
    padding: 0 40px;
  }
  .logo-tit{
    font-size: 24px;
    color: #fff;
  }
  .login-bg1{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('~@/assets/image/login/bg1.png') no-repeat center top;
    background-size: cover;
  }
  .login{
    width: 482px;
    height: 460px;
    position: absolute;
    left: calc(50% + 286px);
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 4px;
    .el-form-item{
      margin-bottom: 30px;
    }
  }
  .login-box{
    perspective: 5000;
    -webkit-perspective: 5000;
    -ms-perspective: 5000;
    -moz-perspective: 5000;
    -o-perspective: 5000;
    transition: 0.8s;
    position: relative;
    perspective-origin: 0 100%;
    -webkit-perspective-origin: 0 100%;
    -ms-perspective: 500 100%00;
    -moz-perspective: 0 100%;
    -o-perspective: 0 100%;
  }
  .user-login{
    padding: 60px 40px 60px 40px;
    background: #fff;

    position: absolute;
    left: 0;
    top: 0;
    width: 402px;
  }
  .login-top{
    border-bottom: 1px solid #DCDFE6;
    margin-bottom: 10px;
    background: #f4f4f4;
  }
  .news, .password{
    float: left;
    width: 50%;
    text-align: center;
    line-height: 70px;
    font-size: 18px;
    color: #666;
    cursor: pointer;
  }
  .login-footer{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 55px;
    text-align: center;
    color: #666;
    font-size: 14px;
    padding-top: 25px;
    a{
      color: #007dff;
    }
  }
  .center-opcity{
    height: 240px;
    width: 100%;
    background: rgba(245, 249, 252, 0.4);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  .tabe-tit1{
    font-size: 48px;
    color: #040d18;
  }
  @media screen and (max-width: 1440px){
    .tabe-tit1{
      font-size: 44px;
    }
  }
  .tabe-tit2{
    font-size: 36px;
    color: #333;
  }
  .pos-tit{
    position: absolute;
    right: 582px;
    top: 164px;
    width: 482px;
    text-align: right;
  }
</style>
