<template>
  <!-- 密码登录 -->
  <transition name="fade">
    <div class="user-login pass-login-other" :class="[showPassWordLogin?'aa':'go']" v-show="showPassWordLogin">
      <div class="register-tit clearfix">
        <span class="left-span"><span class="res-line"></span>密码登录</span>
        <span class="right-span" @click="goNews">短信登录></span>
      </div>
      <el-form :model="passWordRuleForm" :rules="passWordNewsRules" ref="passWordRuleForm" class="demo-ruleForm">
        <el-form-item label="用户名" :class="{styleName: isStyleName}" prop="userName">
          <el-input class="user-ipn" @focus="NameMailFocus" @blur="NameMailBlur" v-model="passWordRuleForm.userName" maxlength="30" placeholder="请输入用户名/邮箱/手机号"></el-input>
        </el-form-item>
        <el-form-item class="code-pos" :class="{styleName: isStylePass}" label="密码" prop="password">
          <el-input class="pass-ipn" @focus="PassFocus" @blur="PassBlur" type="password" v-model="passWordRuleForm.password" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
        <el-form-item class="code-pos" :class="{styleName: isStyleImgCode}" label="验证码" prop="imgCode">
          <el-input class="pass-ipn" @focus="ImgCodeFocus" @blur="ImgCodeBlur" v-model="passWordRuleForm.imgCode" placeholder="请输入验证码"></el-input>
          <div class="img-code" @click="GetImgCode">
            <img :src='imageCode'>
          </div>
        </el-form-item>
      </el-form>
      <el-button class="subbtn" type="primary" @click="passwordSubmitForm('passWordRuleForm')">立即登录</el-button>
      <div class="register">
        <span @click="ResetPassword">忘记密码 | </span>
        <span @click="GoRegister">没有账号，去注册></span>
      </div>
    </div>
  </transition>
</template>
<script>
import axios from 'axios'
// import { h } from 'vue'
import { baseHost } from '@/assets/js/PublicData.js'
import { request } from '@/assets/js/http.js'
export default {
  name: 'PasswordLogin',
  data () {
    // 密码验证
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 6) {
        callback(new Error('密码长度最小6位'))
      } else {
        callback()
      }
    }
    // 验证码验证
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else if (value.length < 4) {
        callback(new Error('验证码必须是4位'))
      } else {
        callback()
      }
    }
    return {
      isStyleName: false,
      isStylePass: false,
      isStyleImgCode: false,
      rotate: false,
      showPassWordLogin: true,
      imageCode: '',
      // 短信登录校验
      passWordRuleForm: {
        userName: '',
        password: '',
        imgCode: ''
      },
      passWordNewsRules: {
        userName: [
          { required: true, message: '请输入用户名/邮箱', trigger: ['blur'] }
        ],
        password: [
          { required: true, validator: validatePass, trigger: ['blur'] }
        ],
        imgCode: [
          { required: true, validator: validateCode, trigger: ['blur'] }
        ]
      }
    }
  },
  created () {
    localStorage.setItem('token', '')
    this.GetImgCode()
  },
  mounted () {
    // 绑定enter事件
    this.enterKeyup()
  },
  onUnmounted () {
    // 销毁enter事件
    this.enterKeyupDestroyed()
  },
  methods: {
    // 切换短信登录
    goNews () {
      this.$emit('goNews')
    },
    // 获取图片验证码
    GetImgCode () {
      axios.get(baseHost.SupplierRegHost + '/api/system/user/checkImg', { responseType: 'blob' }).then((res) => {
        this.imageCode = window.URL.createObjectURL(res.data)
      })
    },
    // 密码登录
    passwordSubmitForm (passWordRuleForm) {
      this.$refs[passWordRuleForm].validate((valid) => {
        if (valid) {
          const obj = {
            loginWay: 1,
            username: this.passWordRuleForm.userName,
            password: this.passWordRuleForm.password,
            checkCode: this.passWordRuleForm.imgCode
          }
          axios.post(baseHost.SupplierRegHost + '/api/auth/userlogin', obj).then((res) => {
            if (res.data.code === '200') {
              localStorage.setItem('token', res.data.data)
              const goUrl = sessionStorage.getItem('redirect')
              if (goUrl === null || goUrl === 'undefined' || goUrl === '/login') {
                this.$router.push({ path: '/index' })
              } else {
                location.href = goUrl
              }
              request('/api/system/user/getUserInfoAndMenuMessage', 'GET', {}).then((response) => {
                localStorage.setItem('userName', response.data.username)
                localStorage.setItem('userId', response.data.id)
                sessionStorage.setItem('businessScope', response.data.businessScope)
                this.$store.commit('setLoginInfo', response.data)
                sessionStorage.removeItem('redirect')
                this.$store.commit('setMenuData', response.data.permissions)
                sessionStorage.setItem('enterpriseCode', response.data.enterpriseCode)
                if (response.data.enterpriseCode === null && response.data.userType === 1) {
                  this.$router.push({ name: 'frame', params: { frameName: 'myCompany', viewName: 'SupplierRegistration' } })
                }
              })
              // this.$notify({
              //   title: '欢迎',
              //   message: h('i', { style: 'color: teal' }, '欢迎使用本系统')
              // })
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
          })
        } else {
          return false
        }
      })
    },
    // 清空表单
    PassResetForm (passWordRuleForm) {
      this.$refs[passWordRuleForm].resetFields()
    },
    // 回车登录
    enterKey (event) {
      const code = event.keyCode
      if (code === 13) {
        this.passwordSubmitForm('passWordRuleForm')
      }
    },
    enterKeyupDestroyed () {
      window.removeEventListener('keyup', this.enterKey)
    },
    enterKeyup () {
      window.addEventListener('keyup', this.enterKey)
    },
    // 跳转注册页
    GoRegister () {
      this.$router.push({
        path: '/Register'
      })
    },
    // 跳转重置密码页
    ResetPassword () {
      if (this.passWordRuleForm.userName.indexOf('@') !== -1) {
        this.$router.push({
          name: 'PasswordReset',
          params: {
            name: this.passWordRuleForm.userName
          }
        })
      } else {
        this.$router.push({
          path: '/PasswordReset'
        })
      }
    },
    // 用户名获取焦点与失去焦点提示
    NameMailFocus () {
      this.isStyleName = true
    },
    NameMailBlur () {
      this.isStyleName = false
    },
    // 密码获取焦点与失去焦点提示
    PassFocus () {
      this.isStylePass = true
    },
    PassBlur () {
      this.isStylePass = false
    },
    // 验证码获取焦点与失去焦点提示
    ImgCodeFocus () {
      this.isStyleImgCode = true
    },
    ImgCodeBlur () {
      this.isStyleImgCode = false
    }
  },
  watch: {
    'passWordRuleForm.userName' (news, old) {
      if (news.indexOf('@') !== -1) {
        console.log('邮箱')
      }
    }
  }
}
</script>

<style scoped lang='scss'>
  @import '@/assets/css/login.scss';
  .register-tit{
    // margin-bottom: 50px;
    margin-bottom: 30px;
  }

  .res-line{
    width: 5px;
    height: 21px;
    background: #4487fa;
    display: inline-block;
    position: absolute;
    left: -15px;
    top: 2px;
  }
  .left-span{
    position: relative;
    float: left;
    margin-left: 14px;
    font-size: 18px;
    color: #666;
  }
  .right-span{
    float: right;
    font-size: 14px;
    color: #666;
    cursor: pointer;
  }
  .right-span:hover{
    color: #409EFF;
  }
  .code-pos{
    position: relative;
    .el-button{
      min-height: 38px;
      border: none;
    }
  }
  .subbtn{
    width: 100%;
    margin: 10px 0 0;
    font-size: 18px;
  }
  .register span{
    cursor: pointer;
  }
  .register{
    margin-top: 16px;
    font-size: 14px;
    text-align: center;
    color: #ff6542;
    line-height: 1;
  }
  .pass-login-other .el-form-item{
    // margin-bottom: 50px;
    margin-bottom: 26px;
  }
  .styleName :deep(.el-form-item__error){
    display: none;
  }
  .styleName :deep(.el-input__inner){
    border-color: #F56C6C;
  }
  .img-code{
    width: 90px;
    line-height: 1;
    position: absolute;
    right: 0;
    bottom: 12px;
    cursor: pointer;
  }
  .img-code img{
    width: 100%;
  }
  .aa{
     transition: all .8s;
    -ms-transition: all .8s;
    -moz-transition: all .8s;
    -webkit-transition: all .8s;
    -o-transition: all .8s;
  }
  .go{
    transform: rotateY(0deg);
    -ms-transform:rotateY(0deg);
    -moz-transform:rotateY(0deg);
    -webkit-transform:rotateY(0deg);
    -o-transform:rotateY(0deg);

   transition: all .8s;
    -ms-transition: all .8s;
    -moz-transition: all .8s;
    -webkit-transition: all .8s;
    -o-transition: all .8s;
  }
  .fade-enter-active, .fade-leave-active {
   transition: all .8s;
    -ms-transition: all .8s;
    -moz-transition: all .8s;
    -webkit-transition: all .8s;
    -o-transition: all .8s;

    transform: rotateY(0deg);
    -ms-transform:rotateY(0deg);
    -moz-transform:rotateY(0deg);
    -webkit-transform:rotateY(0deg);
    -o-transform:rotateY(0deg);
  }
.fade-enter-from,
.fade-leave-to {
     transition: all .8s;
    -ms-transition: all .8s;
    -moz-transition: all .8s;
    -webkit-transition: all .8s;
    -o-transition: all .8s;

    transform: rotateY(-180deg);
    -ms-transform:rotateY(-180deg);
    -moz-transform:rotateY(-180deg);
    -webkit-transform:rotateY(-180deg);
    -o-transform:rotateY(-180deg);
  }
  .user-login{
    position: relative;
    z-index: 7;
    backface-visibility: hidden;
    transform-style: preserve-3d;
  }
</style>
