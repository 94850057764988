<template>
  <!-- 短信登录 -->
  <transition name="fade">
    <div class="user-login user-login-other" :class="[showNewsLogin?'go':'aa']" v-show="showNewsLogin">
      <div class="register-tit clearfix">
        <span class="left-span"><span class="res-line"></span>短信登录</span>
        <span class="right-span" @click="goPassword">密码登录></span>
      </div>
      <el-form :model="newsRuleForm" :rules="newsRules" ref="newsRuleForm" class="demo-ruleForm">
        <el-form-item label="手机号" :class="{styleName: isStylePhone}" prop="phone">
          <el-input @focus="PhoneFocus" @blur="PhoneBlur" v-model="newsRuleForm.phone" maxlength="18" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item class="code-pos" :class="{styleName: isStyleCode}" label="验证码" prop="code">
          <el-input @focus="CodeFocus" @blur="CodeBlur" v-model="newsRuleForm.code" placeholder="请输入验证码"></el-input>
          <el-button class="get-code" @click="getCode" :disabled="codeDisabled" type="primary" plain>{{ btnTxt }}</el-button>
        </el-form-item>
      </el-form>
      <el-button class="subbtn" type="primary" @click="newsSubmitForm('newsRuleForm')">立即登录</el-button>
      <div class="register">
        <span @click="GoRegister">没有账号，去注册></span>
      </div>
    </div>
  </transition>
</template>
<script>
import axios from 'axios'
// import { h } from 'vue'
import { baseHost } from '@/assets/js/PublicData.js'
import { request } from '@/assets/js/http.js'
export default {
  name: 'NewsLogin',
  data () {
    // 手机号验证
    var validateMobilePhone = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('请输入手机号码'))
      } else {
        if (value !== '') {
          // var reg = /^1[3456789]\d{9}$/
          var regs = value.replace(/\s+/g, '')
          var reg = /^1\s*[34578]\s*(\d\s*){9}$/
          if (!reg.test(regs)) {
            callback(new Error('手机号码错误'))
          } else {
            this.codeDisabled = false
          }
        }
        callback()
      }
    }
    // 验证码验证
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else {
        if (value !== '') {
          var reg = /^\d{4}$/
          if (!reg.test(value)) {
            callback(new Error('验证码必须是4位'))
          }
        }
        callback()
      }
    }
    return {
      isStylePhone: false,
      isStyleCode: false,
      rotate: false,
      showNewsLogin: false,
      codeTime: '',
      btnTxt: '获取验证码',
      timer: null,
      codeDisabled: true,
      // 密码登录校验
      newsRuleForm: {
        phone: '',
        code: ''
      },
      newsRules: {
        phone: [
          { required: true, validator: validateMobilePhone, trigger: ['blur'] }
        ],
        code: [
          { required: true, validator: validateCode, trigger: ['blur'] }
        ]
      }
    }
  },
  created () {
    localStorage.setItem('token', '')
  },
  mounted () {
    // 绑定enter事件
    this.enterKeyup()
  },
  onUnmounted () {
    // 销毁enter事件
    this.enterKeyupDestroyed()
  },
  methods: {
    // 切换密码登录
    goPassword () {
      this.$emit('goPassword')
    },
    // 获取验证码
    getCode () {
      axios.get(baseHost.SupplierRegHost + '/api/system/user/sendSmsByPhone?phone=' + this.newsRuleForm.phone).then((res) => {
        if (res.data.code === '200') {
          this.codeTime = 60
          if (this.codeTime > 0) {
            var Cthis = this
            this.codeDisabled = true
            this.btnTxt = this.codeTime + '秒后重新获取'
            const timer = setInterval(function () {
              Cthis.btnTxt = (Cthis.codeTime--) + '秒后重新获取'
              if (Cthis.codeTime <= -1) {
                clearInterval(timer)
                Cthis.btnTxt = '获取验证码'
                Cthis.codeDisabled = false
              }
            }, 1000)
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // 登录
    newsSubmitForm (newsRuleForm) {
      this.$refs[newsRuleForm].validate((valid) => {
        if (valid) {
          const obj = {
            loginWay: 2,
            phone: this.newsRuleForm.phone,
            verificationCode: this.newsRuleForm.code
          }
          axios.post(baseHost.SupplierRegHost + '/api/auth/userlogin', obj).then((res) => {
            if (res.data.code === '200') {
              localStorage.setItem('token', res.data.data)
              const goUrl = sessionStorage.getItem('redirect')
              if (goUrl === null || goUrl === 'undefined' || goUrl === '/login') {
                this.$router.push({ path: '/index' })
              } else {
                location.href = goUrl
              }
              request('/api/system/user/getUserInfoAndMenuMessage', 'GET', {}).then((response) => {
                localStorage.setItem('userName', response.data.username)
                localStorage.setItem('userId', response.data.id)
                sessionStorage.setItem('businessScope', response.data.businessScope)
                sessionStorage.removeItem('redirect')
                this.$store.commit('setMenuData', response.data.permissions)
                this.$store.commit('setLoginInfo', response.data)
                sessionStorage.setItem('enterpriseCode', response.data.enterpriseCode)
                if (response.data.enterpriseCode === null && response.data.userType === 1) {
                  this.$router.push({ name: 'frame', params: { frameName: 'myCompany', viewName: 'SupplierRegistration' } })
                }
              })
              // this.$notify({
              //   title: '欢迎',
              //   message: h('i', { style: 'color: teal' }, '欢迎使用本系统')
              // })
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
          })
        } else {
          return false
        }
      })
    },
    // 清空表单
    NewsResetForm (newsRuleForm) {
      this.$refs[newsRuleForm].resetFields()
    },
    // 回车登录
    enterKey (event) {
      const code = event.keyCode
      if (code === 13) {
        this.newsSubmitForm('newsRuleForm')
      }
    },
    enterKeyupDestroyed () {
      window.removeEventListener('keyup', this.enterKey)
    },
    enterKeyup () {
      window.addEventListener('keyup', this.enterKey)
    },
    // 跳转注册页
    GoRegister () {
      this.$router.push({
        path: '/Register'
      })
    },
    // 手机号获取焦点与失去焦点提示
    PhoneFocus () {
      this.isStylePhone = true
    },
    PhoneBlur () {
      this.isStylePhone = false
    },
    // 验证码获取焦点与失去焦点提示
    CodeFocus () {
      this.isStyleCode = true
    },
    CodeBlur () {
      this.isStyleCode = false
    }
  }
}
</script>

<style scoped lang='scss'>
  @import '@/assets/css/login.scss';
  .get-code{
    position: absolute;
    top: 2px;
    right: 0px;
    bottom: 1px;
    padding: 0 10px;
    border-radius: 3px;
  }
  .register-tit{
    margin-bottom: 50px;
  }
  .res-line{
    width: 5px;
    height: 21px;
    background: #409EFF;
    display: inline-block;
    position: absolute;
    left: -15px;
    top: 2px;
  }
  .left-span{
    position: relative;
    float: left;
    margin-left: 14px;
    font-size: 18px;
    color: #666;
  }
  .right-span{
    float: right;
    font-size: 14px;
    color: #666;
    cursor: pointer;
  }
  .right-span:hover{
    color: #409EFF;
  }
  .code-pos{
    position: relative;
    .el-button{
      min-height: 30px;
      border: none;
      color: #409EFF;
      background: none;
    }
  }
  .subbtn{
    width: 100%;
    margin: 10px 0 0;
    font-size: 18px;
  }
  .register span{
    cursor: pointer;
  }
  .register{
    margin-top: 16px;
    font-size: 14px;
    text-align: center;
    color: #ff6542;
    line-height: 1;
  }
  .news, .password{
    float: left;
    width: 50%;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
  }
  .user-login-other .el-form-item{
    margin-bottom: 50px;
  }
  .styleName :deep(.el-form-item__error){
    display: none;
  }
  .styleName :deep(.el-input__inner){
    border-color: #F56C6C;
  }
  .aa {
    transition: all .8s;
    -ms-transition: all .8s;
    -moz-transition: all .8s;
    -webkit-transition: all .8s;
    -o-transition: all .8s;
    transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
}
.go {
    transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);

    transition: all .8s;
    -ms-transition: all .8s;
    -moz-transition: all .8s;
    -webkit-transition: all .8s;
    -o-transition: all .8s;
}
.fade-enter-active,
.fade-leave-active {
   transition: all .8s;
    -ms-transition: all .8s;
    -moz-transition: all .8s;
    -webkit-transition: all .8s;
    -o-transition: all .8s;

    transform: rotateY(0deg);
    -ms-transform:rotateY(0deg);
    -moz-transform:rotateY(0deg);
    -webkit-transform:rotateY(0deg);
    -o-transform:rotateY(0deg);
  }
.fade-enter-from,
.fade-leave-to {
     transition: all .8s;
    -ms-transition: all .8s;
    -moz-transition: all .8s;
    -webkit-transition: all .8s;
    -o-transition: all .8s;

    transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
}
.user-login {
    position: relative;
    z-index: 6;
    backface-visibility: hidden;
    transform-style: preserve-3d;
}
</style>
